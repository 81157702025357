<template>
  <KTPortlet v-bind:title="'Add Customer'">
    <template v-slot:body>
      <v-app>
        <div class="row" v-if="loading && websites && websites.length<1">
          <div class="col-md-12">
            <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
          </div>
        </div>
        <div class="row" v-else-if="!loading && websites && websites.length<1">
          <div class="col-md-12 text-center">
            <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
            <h5 class="mt-10">Looks like you have not created any websites. To add a customer, you will need at-least one website.</h5>
            <a :href="createWebsiteUrl" class="btn btn-primary text-white"
            >Create a new website</a>
          </div>
        </div>
        <v-row v-else>
          <v-col lg="12" md="12" sm="12">
            <v-select
              :items="websites"
              v-model="website_user.website_id"
              dense
              label="Websites"
              item-text="title"
              item-value="id"
              @change="validateEmail"
              outlined
            ></v-select>
            <span class="text-danger" v-if="$v.website_user.website_id.$error">Website is required</span>
          </v-col>
          <v-col lg="6" md="6" sm="6">
            <v-text-field outlined label="First Name" dense v-model="website_user.first_name"></v-text-field>
            <span
              class="text-danger"
              v-if="$v.website_user.first_name.$error"
            >First name is required</span>
          </v-col>

          <v-col lg="6" md="6" sm="6">
            <v-text-field outlined label="Last Name" dense v-model="website_user.last_name"></v-text-field>
            <span class="text-danger" v-if="$v.website_user.last_name.$error">Last name is required</span>
          </v-col>
          <v-col lg="6" md="6" sm="6">
            <v-text-field outlined label="Phone" dense v-model="website_user.phone"></v-text-field>
          </v-col>
          <v-col lg="6" md="6" sm="6">
            <v-text-field
              outlined
              label="Email"
              dense
              v-model="website_user.email"
              @input="validateEmail"
            ></v-text-field>
            <span class="text-danger" v-if="$v.website_user.email.$error">Email is required</span>
          </v-col>

          <v-col lg="6" md="6" sm="6">
            <v-text-field outlined label="Password" dense v-model="website_user.password"></v-text-field>
            <span class="text-danger" v-if="$v.website_user.password.$error">Password is required</span>
          </v-col>
          <v-col lg="6" md="6" sm="6">
            <v-text-field
              outlined
              label="Confirm Password"
              dense
              v-model="website_user.password_confirmation"
            ></v-text-field>
            <span
              class="text-danger"
              v-if="$v.website_user.password_confirmation.$error"
            >Confirmation Password is required</span>
          </v-col>
          <v-col lg="6" md="6" sm="6" class="text-right">
            <v-row>
              <v-switch label="Status" class="m-2" v-model="website_user.is_active"></v-switch>
              <v-switch label="Verified Email" class="m-2" v-model="website_user.is_email_verified"></v-switch>
              <v-switch label="Send Mail" class="m-2" v-model="website_user.send_mail"></v-switch>
            </v-row>
          </v-col>
          <v-col lg="6" md="6" sm="6" class v-if="error">
            <span class="text-danger">
              <h5>{{error_msg}}</h5>
            </span>
          </v-col>
          <!-- <pre>{{website_user}}</pre> -->

          <v-col lg="12" md="12" sm="12" class="text-right">
            <v-btn outlined dense class="m-2" @click="cancel()">Cancel</v-btn>
            <v-btn
              outlined
              dense
              class="m-2"
              :disabled="error"
              :loading="loading"
              @click.prevent="saveOrUpdate"
            >Save</v-btn>
          </v-col>
        </v-row>
      </v-app>
    </template>
  </KTPortlet>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import WebsiteUser from "@/services/store/EcommerceCustomerService";
import WebsiteService from "@/services/Websites/WebsiteService";

const websiteUser = new WebsiteUser();
const websiteService = new WebsiteService();

export default {
  name: "website-user-create-and-update",
  components: {
    KTPortlet
  },
  validations: {
    website_user: {
      email: { required },
      first_name: { required },
      last_name: { required },
      password: { required },
      password_confirmation: { required },
      website_id: { required }
    }
  },
  data() {
    return {
      userId :null,
      websites: [],
      edit: false,
      error: false,
      error_msg: null,
      loading: true,
      createWebsiteUrl: '',
      website_user: {
        email: null,
        first_name: null,
        last_name: null,
        is_active: 1,
        password: null,
        password_confirmation: null,
        phone: null,
        website_id: null
      }
    };
  },
  mounted() {
    this.userId = this.$store.getters.currentUser.id;
    this.createWebsiteUrl = process.env.VUE_APP_SERVER_URL + 'templates';
    this.getAllWebsite();
  },
  methods: {
    getAllWebsite() {
      websiteService
        .getAll()
        .then(response => {
          this.websites = response.data.data;
          this.loading=false;
        })
        .catch(error => {});
    },
    cancel() {
      this.$router.push({ name: "customers-list", params: { status: "all" } });
    },
    saveOrUpdate() {
      this.$v.website_user.$touch();
      if (this.$v.website_user.$error) {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.$v.website_user.$reset();
        }, 3000);
      } else {
        if (!this.edit) {
          this.save();
        } else {
          this.update();
        }
      }
    },
    validateEmail() {
      if (!this.website_user.website_id) {
        this.error = true;
        this.error_msg = "Please select website";
        return;
      }
      websiteUser
        .validateEmail(
          this.userId,
          this.website_user.website_id,
          this.website_user.email
        )
        .then(response => {
          if (response.data) {
            this.error = true;
            this.error_msg = "Email already exist";
          } else {
            this.error = false;
            this.error_msg = null;
          }
        })
        .catch(error => {
          // console.log(error);
        });
    },
    save() {
      websiteUser
        .create(this.userId, this.website_user)
        .then(response => {
          this.$snotify.success("User created successfully");
          setTimeout(() => {
            this.$router.push({ name: "customers-list", params: { status: "all" } });
          }, 3000);
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },update(){
       websiteUser
        .create(this.userId, this.website_user.id, this.website_user)
        .then(response => {
          this.$snotify.success("User updated successfully");
          setTimeout(() => {
            this.$router.push({ name: "customers-list", params: { status: "all" } });
          }, 3000);
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetForm() {
      this.website_user = {
        email: null,
        first_name: null,
        last_name: null,
        is_active: 1,
        password: null,
        password_confirmation: null,
        phone: null,
        user_name: null
      };
      this.edit = false;
      this.$v.website_user.$reset();
    }
  }
};
</script>
